import { useState } from "react";
import { Card, Button, Tabs } from "antd";
import { FundsProvider } from "../../context/FundsContext";
import ListPortfolios from "./ListPortfolios";
import AddPortfolios from "./AddPortfolios";
import TimePortfolios from "./TimePortfolios";

const FundsPortfolio = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isReloadList, setIsReloadList] = useState(false);

  const handleCloseModal = (isReload) => {
    if (isReload) {
      setIsReloadList((prevIsReloadList) => !prevIsReloadList);
    }
    setIsVisible(false);
  };

  const items = [
    {
      key: "1",
      label: "Phân tích chung",
      children: <ListPortfolios isReload={isReloadList} />,
    },
    {
      key: "2",
      label: "Phân tích theo thời gian",
      children: <TimePortfolios isReload={isReloadList} />,
    },
  ];

  return (
    <FundsProvider>
      <Card
        bordered={false}
        title="Danh mục đầu tư các quỹ"
        styles={{ body: { padding: 0 } }}
        extra={
          <Button size="small" onClick={() => setIsVisible(true)}>
            Import
          </Button>
        }>
        {isVisible && <AddPortfolios isOpenModal={isVisible} onClose={handleCloseModal} />}

        <Tabs
          size="small"
          defaultActiveKey="1"
          items={items}
          style={{ padding: "0 1.5rem", margin: 0 }}
          tabBarStyle={{ margin: 0 }}
        />
      </Card>
    </FundsProvider>
  );
};

export default FundsPortfolio;
