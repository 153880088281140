import { useState, useEffect } from "react";
import { Card, Table } from "antd";
import { fetchData } from "../../services/realmServices";
import { formatNumberWithCommas, formatToPercentage } from "../../services/common";
import FullScreenLoader from "../../components/common/FullScreenLoader";

const Funds = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    async function fetchDataFromRealm() {
      try {
        const result = await fetchData("funds");
        const converedData = result.map((e, index) => {
          return {
            ...e,
            key: index.toString(),
          };
        });
        setData(converedData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    fetchDataFromRealm();
  }, []);

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "8%",
      fixed: "left",
      render: (_text, _record, index) => index + 1,
    },
    {
      title: "Tổ chức",
      dataIndex: "organization_name",
      key: "organization_name",
      ellipsis: true,
    },
    {
      title: "Mã quỹ",
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      ellipsis: true,
      width: "10%",
    },
    {
      title: "Tên quỹ",
      dataIndex: "fund_name",
      key: "fund_name",
      ellipsis: true,
    },
    {
      title: "Tổng tài sản",
      dataIndex: "total_asset",
      key: "total_asset",
      align: "center",
      sorter: (a, b) => a.total_asset - b.total_asset,
      sortOrder: sortedInfo.columnKey === "total_asset" ? sortedInfo.order : null,
      ellipsis: true,
      render: (numberString) => formatNumberWithCommas(numberString),
    },
    {
      title: "Phí (12 tháng)",
      dataIndex: "fee",
      key: "fee",
      align: "center",
      sorter: (a, b) => a.fee - b.fee,
      sortOrder: sortedInfo.columnKey === "fee" ? sortedInfo.order : null,
      ellipsis: true,
      render: (numberString) => formatToPercentage(numberString),
    },
    {
      title: "Ngày thành lập",
      dataIndex: "establish_date",
      key: "establish_date",
      align: "center",
      ellipsis: true,
    },
  ];

  return (
    <Card
      bordered={false}
      title="Danh sách quỹ đầu tư"
      extra={!loading && <h4>Tổng: {data.length}</h4>}
      styles={{ body: { padding: 0 } }}>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={{
          pageSize: 50,
          position: ["bottomCenter"],
        }}
        scroll={{
          y: "70vh",
        }}
      />
      {loading && <FullScreenLoader />}
    </Card>
  );
};

export default Funds;
