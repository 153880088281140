import { useState, useEffect, useCallback } from "react";
import { Table, Select, Space, Input, Spin, Flex, DatePicker } from "antd";
import { fetchDataWithQuery } from "../../../services/realmServices";
import { formatNumberWithCommas, formatToPercentage } from "../../../services/common";
import { useFundsContext } from "../../../context/FundsContext";
const { RangePicker } = DatePicker;

const TimePortfolios = ({ isReload }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});

  const { fundsContext } = useFundsContext();

  const [fundSelected, setFundSelected] = useState(null);
  const [times, setTimes] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const fetchDataFromRealm = useCallback(async (fundSelected, times, searchValue) => {
    try {
      let queryObject = {
        year: { $gte: times[0].$y, $lte: times[1].$y },
        month: { $gte: times[0].$M + 1, $lte: times[1].$M + 1 },
      };

      if (fundSelected) queryObject = { ...queryObject, "data_fund.fund_code": fundSelected };
      if (searchValue) queryObject = { ...queryObject, stock_code: { $regex: searchValue, $options: "i" } };

      setLoading(true);
      const result = await fetchDataWithQuery("funds_portfolio", queryObject);
      if (result.length === 0) {
        setData([]);
      }

      const converedData = result.map((e, index) => {
        return {
          ...e,
          key: index.toString(),
          index: index + 1,
          fund_code: e.data_fund.fund_code,
          organization_name: e.data_fund.organization_name,
        };
      });
      setData(converedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (times.length === 0) return;
    fetchDataFromRealm(fundSelected, times, searchValue);
  }, [isReload, fundSelected, times, searchValue, fetchDataFromRealm]);

  const handleChange = (_pagination, _filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      ellipsis: true,
      width: "8%",
      fixed: "left",
      render: (_text, _record, index) => index + 1,
    },
    {
      title: "Mã",
      dataIndex: "stock_code",
      key: "stock_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
    },
    {
      title: "Quỹ",
      dataIndex: "fund_code",
      key: "fund_code",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
    },
    {
      title: "Tổ chức",
      dataIndex: "organization_name",
      key: "organization_name",
      ellipsis: true,
    },
    {
      title: "Tháng",
      dataIndex: "month",
      key: "month",
      align: "center",
      ellipsis: true,
      width: "10%",
      fixed: "left",
      sorter: (a, b) => a.month - b.month,
      sortOrder: sortedInfo.columnKey === "month" ? sortedInfo.order : null,
    },
    {
      title: "Số lượng CP",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.quantity - b.quantity,
      sortOrder: sortedInfo.columnKey === "quantity" ? sortedInfo.order : null,
      render: (numberString) => formatNumberWithCommas(numberString),
    },
    {
      title: "Giá thị trường",
      dataIndex: "market_price",
      key: "market_price",
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.market_price - b.market_price,
      sortOrder: sortedInfo.columnKey === "market_price" ? sortedInfo.order : null,
      render: (numberString) => formatNumberWithCommas(numberString),
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.value - b.value,
      sortOrder: sortedInfo.columnKey === "value" ? sortedInfo.order : null,
      render: (numberString) => formatNumberWithCommas(numberString),
    },
    {
      title: "Tỉ trọng",
      dataIndex: "portfolio_rate",
      key: "portfolio_rate",
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.portfolio_rate - b.portfolio_rate,
      sortOrder: sortedInfo.columnKey === "portfolio_rate" ? sortedInfo.order : null,
      render: (numberString) => formatToPercentage(numberString),
    },
  ];

  return (
    <>
      <Flex justify="space-between">
        <Space size={12}>
          <Select
            allowClear
            showSearch
            placeholder="Quỹ đầu tư"
            onChange={(value) => setFundSelected(value)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            options={fundsContext}
            style={{ width: 250 }}
          />
          <RangePicker picker="month" onChange={(value) => setTimes(value)} />
          <Input
            placeholder="Mã cổ phiếu"
            size="small"
            style={{ height: "2rem", fontWeight: "400" }}
            onPressEnter={(e) => setSearchValue(e.target.value)}
          />
          {loading && <Spin />}
        </Space>

        <h4>Tổng: {data.length}</h4>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        className="ant-border-space"
        pagination={{
          pageSize: 50,
          position: ["bottomCenter"],
        }}
        scroll={{
          y: 550,
        }}
      />
    </>
  );
};
export default TimePortfolios;
