import * as Realm from "realm-web";

const realmAppId = "investment-admin-zhtqrrs";

const databaseName = "investment-admin";

// Initialize Realm App
const app = new Realm.App({ id: realmAppId });

const autoLoginToRealmApp = async () => {
  try {
    let user = app.currentUser;

    if (!user) {
      console.log("No existing user. Logging in anonymously...");
      const credentials = Realm.Credentials.anonymous();
      user = await app.logIn(credentials);
    }

    return user;
  } catch (error) {
    console.error("Error during auto login:", error);
    throw error;
  }
};

// Fetch the current user (if available)
const getCurrentUser = () => {
  return app.currentUser;
};

async function fetchData(nameCollection) {
  try {
    const user = await autoLoginToRealmApp();
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db(databaseName).collection(nameCollection);
    const result = await collection.find({});
    return result;
  } catch (error) {
    console.error("Failed to log in or fetch data:", error);
  }
}

async function fetchDataWithQuery(nameCollection, queryObject) {
  try {
    const user = await autoLoginToRealmApp();
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db("investment-admin").collection(nameCollection);
    const result = await collection.find(queryObject);
    return result;
  } catch (error) {
    console.error("Failed to log in or fetch data:", error);
  }
}

async function fetchDataWithGroupOrder(nameCollection, pipeline) {
  const credentials = Realm.Credentials.anonymous();
  try {
    const user = await app.logIn(credentials);
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db("investment-admin").collection(nameCollection);
    const result = await collection.aggregate(pipeline);
    return result;
  } catch (error) {
    console.error("Failed to log in or fetch data with group order:", error);
  }
}

async function addDataToRealm(nameCollection, newData) {
  const credentials = Realm.Credentials.anonymous();
  try {
    const user = await app.logIn(credentials);
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db("investment-admin").collection(nameCollection);

    const result = await collection.insertOne(newData);
    return result;
  } catch (error) {
    console.error("Failed to add data:", error);
    throw error;
  }
}

async function addArrayDataToRealm(nameCollection, newArrayData) {
  const credentials = Realm.Credentials.anonymous();
  try {
    const user = await app.logIn(credentials);
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db(databaseName).collection(nameCollection);

    const result = await collection.insertMany(newArrayData);
    return result;
  } catch (error) {
    console.error("Failed to add data:", error);
    throw error;
  }
}

export {
  autoLoginToRealmApp,
  getCurrentUser,
  fetchData,
  fetchDataWithQuery,
  fetchDataWithGroupOrder,
  addDataToRealm,
  addArrayDataToRealm,
};
