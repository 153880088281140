import { useState, useEffect } from "react";
import { Modal, Form, Select, InputNumber, Button, message, Upload, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import AntTable from "../../../components/common/AntTable";
import { fetchData, addArrayDataToRealm } from "../../../services/realmServices";

const AddPortfolios = ({ isOpenModal, onClose }) => {
  const [data, setData] = useState([]);
  const [dataFunds, setDataFunds] = useState([]);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const width = "90%";

  useEffect(() => {
    const fetchDataFromRealm = async () => {
      try {
        const result = await fetchData("funds");
        const converedData = result.map((e) => {
          return {
            value: e.fund_code,
            label: `${e.organization_name} - ${e.fund_code}`,
            ...e,
          };
        });
        setDataFunds(converedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataFromRealm();
  }, []);

  const initialValues = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  };

  const handleCloseModal = (isReload) => {
    setData([]);
    onClose(isReload);
  };

  const onSubmit = () => {
    setSubmiting(true);

    form
      .validateFields()
      .then(async (values) => {
        form.resetFields();
        const fundData = dataFunds.find((e) => e.fund_code === values.fund);
        const convertedData = data.map((e) => {
          return {
            stock_code: e.stock_code,
            quantity: e.quantity,
            market_price: e.market_price,
            value: e.value,
            portfolio_rate: e.portfolio_rate,
            year: values.year,
            month: values.month,
            data_fund: {
              fund_code: fundData.fund_code,
              fund_name: fundData.fund_name,
              organization_name: fundData.organization_name,
            },
          };
        });

        try {
          await addArrayDataToRealm("funds_portfolio", convertedData);
          message.success("Thêm dữ liệu thành công!");
          setData([]);
          handleCloseModal(true);
        } catch (error) {
          message.error("Thêm dữ liệu thất bại!");
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  // select
  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // upload
  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Prevent the default upload behavior
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setData(jsonData);
      };

      reader.readAsBinaryString(file);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "removed") {
        message.success(`${info.file.name} file removed successfully.`);
      }
    },
  };

  return (
    <Modal
      title={<h3>Thêm mới danh mục đầu tư</h3>}
      centered
      open={isOpenModal}
      onOk={onSubmit}
      onCancel={() => handleCloseModal(false)}
      width={width}>
      <Form form={form} layout="inline" initialValues={initialValues}>
        <Form.Item
          label="Quỹ đầu tư"
          name="fund"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn quỹ!",
            },
          ]}>
          <Select
            showSearch
            placeholder="Chọn quỹ đầu tư"
            filterOption={filterOption}
            options={dataFunds}
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item
          name="year"
          label="Kỳ thống kê"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn năm!",
            },
          ]}>
          <InputNumber placeholder="Năm" />
        </Form.Item>
        <Form.Item
          name="month"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn tháng!",
            },
          ]}>
          <InputNumber placeholder="Tháng" />
        </Form.Item>
        <Form.Item
          name="data-upload"
          label="File import (excel)"
          rules={[{ required: true, message: "Vui lòng chọn file!" }]}>
          <Upload {...props}>
            <Button loading={submiting} size="small" icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      {data.length > 0 && (
        <Spin spinning={submiting}>
          <h4>Danh sách danh mục preview</h4>
          <AntTable data={data} />
        </Spin>
      )}
    </Modal>
  );
};
export default AddPortfolios;
