import React from "react";
import { Table, Tooltip } from "antd";

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const AntTable = ({ data }) => {
  if (data.length === 0) {
    return null;
  }

  const columns = [];
  Object.keys(data[0]).forEach((key) => {
    if (key !== "_id") {
      columns.push({
        title: key,
        dataIndex: key,
        render: (key) => (
          <Tooltip placement="topLeft" title={key}>
            {key}
          </Tooltip>
        ),
      });
    }
  });

  const convertedData = data.map((e, index) => {
    return {
      key: index,
      ...e,
    };
  });

  return (
    <Table
      columns={columns}
      dataSource={convertedData}
      onChange={onChange}
      showSorterTooltip={{
        target: "sorter-icon",
      }}
    />
  );
};
export default AntTable;
