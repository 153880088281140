import { Button, Result, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { routeData } from "../../services/routers";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Flex height="100%" justify="center" align="center">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button onClick={() => navigate(`/${routeData.funds}`)}>Back Home</Button>}
      />
    </Flex>
  );
};

export default NotFound;
